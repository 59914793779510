import { InjectionToken } from '@angular/core';

export const ICONS = new InjectionToken('UI Icons', {
  factory: () => {
    return [
      'close',
      'profile',
      'plan',
      'edit',
      'attachment',
      'delete',
      'forward',
      'checkmark',
      'chevron-right',
      'chevron-down',
      'rotate-left',
      'rotate-right',
      'revert',
      'upload',
      'info',
      'wall-plan',
      'window',
      'arrow-down',
      'arrow-up',
      'back',
      'forward',
      'add',
      'archive-2',
      'list',
      'tiles',
      'search',
      'kebab-horizontal',
      'kebab-vertical',
      'clone',
      'chevron-left',
      'avatar',
      'unarchive',
      'save',
      'flag-dutch',
      'flag-english',
      'settings',
      'download',
      'download-file-icon',
      'undo',
      'redo',
      'refresh',
      'lock',
      'visibility-on',
      'visibility-off',
      'badge',
      'external',
      'file-pdf',
      'file-xls',
      'technical',
      'star',
      'star-border',
      'star-half',
    ];
  }
});
